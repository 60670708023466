#livestream-video {
  position: relative;
  width: 100%;
  height: calc(100vw * 0.5625);

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: $desktop) {
  #livestream-video {
    height: calc(((100vw - 9.5rem) * (10 / 13)) * 0.5625);
  }
}

@media (min-width: $desktop-wide) {
  #livestream-video {
    height: calc(((100vw - 10rem) * (10 / 13)) * 0.5625);
  }
}