#video-background {
  position: absolute;
  width: 100%;
  height: 150vh;
  top: 0;
  left: 0;
  overflow-x: hidden;
  z-index: -1;
}

#video-background.fixed {
  position: fixed;
  
}

#video-container {
  height: 100vh;
}

#video-container.right {
  text-align: right;
}

#video-container.left {
  text-align: left;
}

#video-container.center {
  text-align: center;
  
}

#video-container video, #video-container img {
  object-fit: cover;
  width: 100vw;
  height: 100vh;

  &.livestream {
    opacity: 0.3;
  }
}

#video-container video {
  display: none;
}

#video-container img {
  display: block;
}

@media (min-width: $desktop) {
  #video-container video {
    display: block;
  }

  #video-container img {
    display: none;
  }
}