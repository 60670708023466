#intro-graphic {
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
}

#graphic-container {
  width: 100%;
  max-width: 34rem;
  height: 475px;
  margin: 0 auto;
  position: relative;
}

#logo {
  position: absolute;
  top: 3rem;
  right: 0;
}

#globe-graphic {
  position: absolute;
  top: 0;
  left: calc((34rem - 440px) / 2);
  width: 440px;
  height: 440px;
  // background: $darkgray;
  // color: $lightgray;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
}

#animation {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 226px;
  height: 226px;
  border: 1px solid $light;
  border-radius: 50%;
  color: $lightgray;
  display: grid;
  align-items: center;
  justify-items: center;
}

@media (min-width: $mobile-horizontal) {
  #intro-graphic {
    display: grid;
  }
}