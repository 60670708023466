#down-button {
  display: none;
  width: 44px;
  position: absolute;
  left: calc(50vw - 22px);
  bottom: 2.5rem;
  cursor: pointer;
  // animation: fade_move_down 3s cubic-bezier(0.19, 1, 0.22, 1) infinite;
  transition: transform 0.25s;

  &:hover {
    transform: skew(15deg, 15deg);
  }
}

@media (min-width: $mobile-horizontal) {
  #down-button {
    display: block;
  }
}

// @keyframes fade_move_down {
//   0% {
//     transform: translate(0, -25px);
//     opacity: 0;
//   }

//   30% {
//     opacity: 1;
//   }

//   85% {
//     opacity: 1;
//   }
  
//   100% {
//     transform: translate(0, 5px);
//     opacity: 0;
//   }
// }