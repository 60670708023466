#about-brief {
  background: $dark;
  padding: 3.75rem 2rem;
  display: grid;
}

#about-graphic {
  width: 100%;
  max-width: 500px;
  height: 500px;
  margin-bottom: 65px;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    transform: scale(1.1);
  }
}

#about-content {
  width: 100%;
  max-width: 500px;
}

@media (min-width: $desktop) {
  #about-brief {
    padding: 8.75rem 6.25rem;
    grid-template-columns: 500px 1fr;
    grid-gap: 0 3.125rem;
  }

  #about-graphic {
    margin-bottom: 0;
  }

  #about-content h4 {
    margin-top: 3.125rem;
  }
}

@media (min-width: $desktop-wide) {
  #about-brief {
    grid-gap: 0 7.5rem;
  }
}
