#shop-popup {
  width: calc(100% - 40px);
  max-width: 400px;
  min-height: 50px;
  // height: 160px;
  background: $dark;
  color: $light;
  border: 1px solid $accent-transparent;
  background: linear-gradient(250deg, hsl(263, 48%, 20%), #1a3e3a);
  background: linear-gradient(
    85.08deg,
    rgba(45, 58, 61, 1) -11.61%,
    rgba(0, 0, 0, 1) 100%
  );
  // box-shadow: 1px 1px 10px 5px #c6fff49e;
  position: fixed;
  z-index: 10;
  right: 20px;
  bottom: 20px;
  animation: pulseborder 3s linear infinite;
  cursor: pointer;
  text-decoration: none;

  p {
    @include button-text;
    margin: 0;
  }

  // h1 {
  //   margin: 45px 0 0;
  // }

  // p {
  //   margin-top: 0;
  // }
}

@media (min-width: $tablet) {
  #shop-popup {
    // background: linear-gradient(250deg, hsla(263, 48%, 20%, 0.5), #1a3e3a88);
    background: linear-gradient(
      85.08deg,
      rgba(45, 58, 61, 0.8) -11.61%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
}

#popup-container {
  width: 100%;
  // height: 100%;
  min-height: 3.125rem;
  position: relative;
  display: grid;
  justify-items: center;
  align-items: center;
}

#close-button {
  height: 18px;
  background: none;
  border: none;
  justify-self: right;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 11;
  cursor: pointer;

  img {
    height: 100%;
    width: 70%;
  }
}

@keyframes pulseborder {
  0% {
    box-shadow: 0px 0px 20px #c6fff49e;
  }

  30% {
    box-shadow: 0px 0px 0px #c6fff49e;
  }

  70% {
    box-shadow: 0px 0px 0px #c6fff49e;
  }

  100% {
    box-shadow: 0px 0px 20px #c6fff49e;
  }
}
