.back-button {
  display: block;
  width: 120px;
  text-decoration: none;
  display: flex;
  transition: transform 0.25s;

  &:hover {
    transform: translateX(-0.25rem);
  }

  h1 {
    margin-left: 1.25rem;
    align-self: center;
  }
}