#livestream-sidebar {
  margin: 2rem;
}

#stream-chat {
  background: none;
  color: $dark;
  width: 100%;
  margin: 0;
  height: 100%;
}

@media (min-width: $desktop) {
  #livestream-sidebar {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(((100vw - 9.5rem) * (10 / 13)) * 0.5625);
  }

  #stream-chat {
    height: calc(100% - 80px);
  }
}

@media (min-width: $desktop-wide) {
  #livestream-sidebar {
    height: calc(((100vw - 10rem) * (10 / 13)) * 0.5625);
  }
}
