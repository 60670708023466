/*** SITE COLORS ***/
$dark: #000;
$light: #fff;
$accent: #9BB3B3;
$accent-transparent: #9BB3B388;
$lightgray: #c1c1c1;
$gray: #959595;
$darkgray: #3c3c3c;

/*** RESPONSIVE BREAKPOINTS ***/
$mobile: 320px;
$mobile-horizontal: 815px; //required to make some things look okay on horizontal iPhone X's
$tablet: 770px;
$desktop: 1024px; //also, tablet-horizontal
$desktop-wide: 1440px; 